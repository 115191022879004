.navbar{
  background: rgb(30,30,30);
  height: 5rem;
  width: 100vw;
  max-width: 75rem;
/* Top & Left: 0 and auto for left and right*/
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top:0;
  z-index: 3;
}

.logo{
  /* Images purposes, so it doesn't strech */
  object-fit: cover;
  /* Before the height was set to: 3 & 6.9 */
  height: 4.5rem;
  /* Before the width was set to: 4 */
  width: 4.2rem;
}
.desktopMenuListItem{
  margin: 1rem;
  color: white;
  cursor: pointer;
}


/* This change navbar titles: Home, About & etc */

.desktopMenuListItem:hover{
  color: #808080;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid #808080;
}
.desktopMenuBtn{
  background: white;
  color: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 2rem;
}
.desktopMenuImg{
  object-fit: cover;
  height: 1rem;
  width: 1rem;
  margin: 1rem;
  cursor: pointer;
}
/* .desktopMenuImg:hover{
    color: #808080 important!;
} */
.active {
color: #808080;
padding-bottom: 0.3rem;
border-bottom: 3px solid #808080;
}
/* This class controls the menu.png image for mobile devices */
.mobMenu{
display: none;
object-fit: cover;
height: 1.8rem;
}
.navMenu{
  position: absolute;
  top: 4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background: rgb(40,40, 40);
  border-radius: 1rem;
}

.listItem{
  color: white;
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background: rgb(30, 30, 30);
}

/*Screen size for Mobile */
@media screen and (max-width:720px){
  .mobMenu{
    /* display: flex; */
  }
  .desktopMenu{
    /* display: none; */
  }
  .desktopMenuBtn{
    /* display: none; */
  }
  .desktopMenuImg{
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
  }
}
@media screen and (max-width:430px){
  .mobMenu{
    /* display: flex;
    object-fit: cover;
    height: 2.9rem;
    margin-right: 5px; */
  }
  .desktopMenuListItem{
    margin: 0.3rem;
    padding: 0 0.1rem;

  }

  .desktopMenu{
    /* display: none; */
  }

  .desktopMenuBtn{
    /* display: none; */
  }

  .desktopMenuImg{
    object-fit: cover;
    /* height: 0.6rem;
    width: 0.6rem;
    margin: 0.5rem;
    font-size: 10px; */
  }

  .desktopMenuBtn{
    font-size: 12px;
  }

}
