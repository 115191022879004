
#contact{
  overflow: hidden;
  width: 100vw;
  max-width: 65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.contactDesc{
  padding: 1rem;
  font-size: medium;
  font-weight: 280;
}

.contactForm{
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;

}

.name, .email, .msg{
  font-size: medium;
  width: 100%;

  max-width: 55rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.4rem;
  background: rgb(40,40,40);
}


.submitBtn{
  background: white;
  color: black;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 3.5rem;

}

.links{
  display: flex;
  flex-wrap: wrap;
}

.link{
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
}
